import React, { useState, useEffect, useContext } from "react";
import TileComponent from "../Tiles";
import HelpButtonContext from "../../contexts/helpContext";
import helpButtonText from "../../contexts/helpButtonText";
import northstarClient from "../../services/northstarClient";

interface InsightsComponentProps {
    showTitle?: boolean
}

const InsightsComponent: React.FC<InsightsComponentProps> = ({showTitle = true}) => {
    const [docs, setDocs] = useState([]);
    const { updateHelpContext } = useContext(HelpButtonContext);
    const GATSBY_API_URL = process.env.GATSBY_API_URL;

    const getDocs = async () => {
        const url = `${GATSBY_API_URL}/api/northstar-publication/get-all-docs`
        const docs = await northstarClient.get(url);
        setDocs(docs);
    }

    useEffect(() => {
        updateHelpContext(helpButtonText.homePage)
        getDocs();
    }, []);

    return (
        <>
            <TileComponent title="Insights" documents={docs} showTitle={showTitle}/>
        </>
    )
}

export default InsightsComponent