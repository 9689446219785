import React, { useEffect, useState } from "react";
import InsightsComponent from "../../components/Insights";
import NorthstarPageLayout from "../../components/NorthstarPageLayout";
import ToolsComponent from "../../components/Tools";
import headerImage from "../../assets/northstar/bg_img.png"
import resourcesIcon from "../../assets/northstar/resources.png"
import teamIcon from "../../assets/northstar/team.png"
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { navigate } from "gatsby";
import northstarClient from "../../services/northstarClient";

interface resource {
   id: number;
   name: string;
   createdAt: string;
   updatedAt: string

}

const Home: React.FC = () => {
   const GATSBY_API_URL = process.env.GATSBY_API_URL;
   const [resources, setResources] = useState<resource[]>([]);

   useEffect(() => {
      const getResources = async () => {
         const res: resource[] = await northstarClient.get(`${GATSBY_API_URL}/api/northstar-resource/get-all-resources`);
         setResources(res);
      }

      getResources()
   }, []);

   const getTimeDifference = (lastUpdated: string) => {
      const currentTime = Date.now();
      const diffTime = currentTime - new Date(lastUpdated);
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays > 1) return `last updated ${diffDays} days ago`;
      else if (diffDays === 1) return `last updated ${diffDays} day ago`;
      else {
         const diffMinutes = Math.floor(diffTime / (1000 * 60));
         if (diffMinutes < 60) {
            return `last updated ${diffMinutes} minutes ago`
         }
         else {
            const diffHours = Math.floor(diffMinutes / 60);
            if (diffHours > 1) return `last updated ${diffHours} hours ago`
            else return `last updated ${diffHours} hour ago`
         }
      }
   }

   return (
      <NorthstarPageLayout title="Northstar Home">
         <>
            <div className="flex gap-x-3 mb-6">
               <div
                  className="h-[450px] grow rounded flex items-center"
                  style={{
                     backgroundImage: `url(${headerImage})`,
                     backgroundRepeat: "no-repeat",
                     backgroundSize: "cover",
                     backgroundPosition: "bottom"
                  }}
               >
                  <div className="pl-6 w-full">
                     <div className="text-blue text-3xl font-title">Find the Northstar</div>
                     <div className="">Our team has years of experience taking great companies<br></br>on a journey to new heights.</div>
                  </div>
               </div>
               <div className="h-[450px] w-[450px] border-gray-light border rounded bg-white">
                  <div className="p-4">
                     <div className="flex items-end">
                        <img src={resourcesIcon} className="h-7 w-7"></img>
                        <div className="font-bold pl-2 pb-1">Resources</div>
                        <div className="grow"></div>
                        <div className="text-sm font-semibold text-end pb-1 text-blue-light cursor-pointer" onClick={() => navigate("/northstar/resources")}>View All</div>
                        <div className="pb-[0.35rem]">
                           <ChevronRightIcon className="text-blue-light h-[1.2rem] cursor-pointer"></ChevronRightIcon>
                        </div>
                     </div>
                     <div className="mt-3">
                        {
                           resources.map((resource) => {
                              return (
                                 <div className={`my-4 flex justify-between items-center ${resources.length !== 4 ? "border-b border-b-gray-light pb-3" : ""}`} key={resource.id}>
                                    <div>
                                       <div className="font-semibold mb-1">{resource.name}</div>
                                       <div className="flex text-sm">
                                          {/* <div>Resources &#x2022;</div> */}
                                          <div>{getTimeDifference(resource.updatedAt)}</div>
                                       </div>
                                    </div>
                                    <ChevronRightIcon className="h-[1.2rem] cursor-pointer" onClick={() => navigate(`/northstar/resources/resource?id=${resource.id}&name=${resource.name}`)}></ChevronRightIcon>
                                 </div>
                              )
                           })
                        }
                     </div>
                  </div>
               </div>
               {/* <div className="h-[450px] w-[450px] border-gray-light border rounded bg-white">
                  <div className="p-4">
                     <div className="flex items-end">
                        <img src={teamIcon} className="h-7 w-7"></img>
                        <div className="font-bold pl-2 pb-1">Contacts</div>
                        <div className="text-sm font-semibold grow text-end pb-1 text-blue-light">View All</div>
                        <div className="pb-[0.35rem]">
                           <ChevronRightIcon className="text-blue-light h-[1.2rem]"></ChevronRightIcon>
                        </div>
                     </div>
                     <div className="mt-3">
                        <div className="pb-3 my-4 border-b border-b-gray-light flex">
                           <img src={dh} className="h-11 rounded-full"></img>
                           <div className="flex w-full justify-between items-center">
                              <div className="ml-4">
                                 <div className="font-semibold mb-1">Diogo Henriques</div>
                                 <div className="text-sm">
                                    <div>Cheif Operating Officer, New York</div>
                                 </div>
                              </div>
                              <ChevronRightIcon className="h-[1.2rem] mb-1"></ChevronRightIcon>
                           </div>
                        </div>
                     </div>
                     <div>
                        <div className="pb-3 my-4 border-b border-b-gray-light flex">
                           <img src={pf} className="h-11 rounded-full"></img>
                           <div className="flex w-full justify-between items-center">
                              <div className="ml-4">
                                 <div className="font-semibold mb-1">Paul Flannery</div>
                                 <div className="text-sm">
                                    <div>Head of Data Strategy, New York</div>
                                 </div>
                              </div>
                              <ChevronRightIcon className="h-[1.2rem] mb-1"></ChevronRightIcon>
                           </div>
                        </div>
                     </div>
                     <div>
                        <div className="pb-3 my-4 border-b border-b-gray-light flex">
                           <img src={eo} className="h-11 rounded-full"></img>
                           <div className="flex w-full justify-between items-center">
                              <div className="ml-4">
                                 <div className="font-semibold mb-1">Elizabeth Ocken</div>
                                 <div className="text-sm">
                                    <div>Operating Director, San Fransisco</div>
                                 </div>
                              </div>
                              <ChevronRightIcon className="h-[1.2rem] mb-1"></ChevronRightIcon>
                           </div>
                        </div>
                     </div>
                     <div>
                        <div className="pb-3 my-4 border-b border-b-gray-light flex">
                           <img src={cf} className="h-11 rounded-full"></img>
                           <div className="flex w-full justify-between items-center">
                              <div className="ml-4">
                                 <div className="font-semibold mb-1">Charlie Flanagan</div>
                                 <div className="text-sm">
                                    <div>Head of Data Science, New York</div>
                                 </div>
                              </div>
                              <ChevronRightIcon className="h-[1.2rem] mb-1"></ChevronRightIcon>
                           </div>
                        </div>
                     </div>
                     <div>
                        <div className="pb-3 my-4 flex">
                           <img src={sa} className="h-11 rounded-full"></img>
                           <div className="flex w-full justify-between items-center">
                              <div className="ml-4">
                                 <div className="font-semibold mb-1">Sid Aziz</div>
                                 <div className="text-sm">
                                    <div>Software Engineer, New York</div>
                                 </div>
                              </div>
                              <ChevronRightIcon className="h-[1.2rem] mb-1"></ChevronRightIcon>
                           </div>
                        </div>
                     </div>
                  </div>
               </div> */}
            </div>
            <ToolsComponent />
            <InsightsComponent />
         </>
      </NorthstarPageLayout>
   )
}

export default Home;